<template>
    <div class="fan-forget">
        <div class="forget-title">
            <img src="../assets/images/nav_logo_1.jpg" alt="">
            <h1>账号安全中心</h1>
        </div>
        <img style="display:block;width:100%;" src="../assets/images/forget-1.jpg" alt="">
        <div class="forget-center">
            <div class="forget-center-infor">
                <h3>修改密码</h3>
                <div class="forget-center-infor-input">
                    <input placeholder="请输入账号" type="text" v-model="formInline.user">
                </div>
                 <div class="forget-center-infor-input">
                    <input placeholder="请输入原密码" type="password" v-model="formInline.password">
                </div>
                 <div class="forget-center-infor-input">
                    <input placeholder="请输入新密码" type="password" v-model="formInline.editPass">
                </div>
                <div class="fan-change" @click="surechange">
                    确认修改
                </div>
            </div>
        </div>
        <footer-info></footer-info>
    </div>
    
</template>
<script>
import axios from "axios";
import base from "../api/base.js";
import FooterInfo from "../components/footer";
import { mapState, mapMutations, mapGetters } from "vuex";
export default {
  components: {
    FooterInfo
  },
  computed: {
    ...mapState(["login", "authStatus", "info"]),
    ...mapGetters(["infoMeta"])
  },
  data() {
    return {
      formInline: {
        user: "",
        password: "",
        editPass: ""
      }
    };
  },
  methods: {
      surechange(){
          var that = this
          let data = {
            uAccounts: that.formInline.user,
            uPass: that.formInline.password,
            editPass: that.formInline.editPass
          }
          axios.get(base.api + "editPass", { params: data }).then(res => {
              console.log(res)
            if (res.data.code == "200") {
              this.$Message.info("修改成功!");
              // 删除缓存
            //   this.set_login("");
              this.$router.push({ path: '/home' })
            } else {
              this.$Message.error(res.data.msg ? res.data.msg : "修改失败!");
            }
          });
      }
  }
};
</script>

<style>
.fan-forget {
  width: 100%;
}
.forget-title {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  width: 1200px;
  height: 116px;
}
.forget-title img:first-child {
  margin-top: 32px;
  width: 145px;
  height: 52px;
}
.forget-title h1 {
  margin-left: 30px;
  line-height: 116px;
  color: #333;
  font-size: 33px;
}
.forget-center {
  background: url("../assets/images/bg-2.jpg") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  box-sizing: border-box;
  padding-top: 125px;
  height: 650px;
}
.forget-center-infor {
  margin: 0 auto;
  width: 60%;
  height: 400px;
  /* border: 1px solid #ccc; */
}
.forget-center-infor h3 {
  padding: 20px 0;
  text-align: center;
}
.forget-center-infor-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  width: 40%;
  height: 80px;
  line-height: 80px;
}
.forget-center-infor-input input {
  margin-top: 20px;
  height: 40px;
  width: 100%;
  padding-left: 20px;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
}

.fan-change{
    width: 200px;
    height: 40px;
    background: rgb(231, 107, 6);
    margin:0 auto;
    line-height: 40px;
    font-size: 16px;
    color: #fff;
    text-align: center;
    border-radius: 5px;
}
</style>
